import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Button from "../components/button"

class IndexPage extends React.Component {
  render() {
    const siteTitle = "ZsirapH weboldala"

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO
          title="ZsirapH Weboldala"
          keywords={[`blog`, `homekit`, `hoobs`, `smarthome`, `homebridge`, `okosotthon`, `okos otthon` ]}
        />
        <h1>
          Üdv{" "}
          <span role="img" aria-label="wave emoji">
            👋
          </span>
        </h1>
        <p>Az oldal elsősorban az okosotthonos, azon belül is HomeKit megoldásokkal kapcsolatos információ- és
           tapasztalatmegosztás céljából jött létre.</p>
        <p>
          Az itt leírtakkal csak óvatosan, kizárólag a saját tapasztalataimat tudom megosztani,
          azokért pedig sajnos nem tudok felelősséget vállalni, simán lehet, hogy valakinél nem pont így fognak működni a dolgok.
        </p>
        <Link to="/blog/">
          <Button marginTop="35px">Irány a blogra!</Button>
        </Link>
      </Layout>
    )
  }
}

export default IndexPage
